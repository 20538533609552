// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@font-face {
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 200 700;
  font-display: swap;
  src: url('assets/fonts/GeneralSans-Variable.woff2') format('woff2');
}

@font-face {
  font-family: 'General Sans';
  font-style: italic;
  font-weight: 200 700;
  font-display: swap;
  src: url('assets/fonts/GeneralSans-VariableItalic.woff2') format('woff2');
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
//@include mat.all-component-typographies(
//  mat.define-typography-config(
//    $font-family: 'General Sans, sans-serif',
//    $body-2: mat.define-typography-level(14px, 20px, 450)
//  )
//);
@include mat.elevation-classes();
@include mat.app-background();

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

$primary-palette: (
  50: #e3f2fd,
  100: #f3f4f6,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: #4f46e5,
  600: #1e88e5,
  700: #1976d2,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: $dark-primary-text,
    100: #111827,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: #ffffff,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$secondary-palette: (
  50: #fce4ec,
  100: #ffffff,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: #585e71,
  600: #d81b60,
  700: #c2185b,
  800: #ad1457,
  900: #880e4f,
  A100: #ff80ab,
  A200: #ff4081,
  A400: #f50057,
  A700: #c51162,
  contrast: (
    50: $dark-primary-text,
    100: #111827,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: #ffffff,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);
$error-palette: (
  50: #ffebee,
  100: #ffdad4,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #ba1b1b,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: $dark-primary-text,
    100: #410001,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: #ffffff,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
//$tumi-app-primary: mat.define-palette($primary-palette);
//$tumi-app-accent: mat.define-palette($secondary-palette);

// The warn palette is optional (defaults to red).
//$tumi-app-warn: mat.define-palette($error-palette);
//
//$tumi-typography: mat.define-typography-config(
//  $font-family: 'General Sans, sans-serif',
//  $body-2: mat.define-typography-level(14px, 20px, 450),
//);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
//$tumi-app-theme: mat.define-light-theme(
//  (
//    color: (
//      primary: $tumi-app-primary,
//      accent: $tumi-app-accent,
//      warn: $tumi-app-warn,
//    ),
//    typography: $tumi-typography,
//  )
//);
//
//$tumi-dark-theme: mat.define-dark-theme(
//  (
//    color: (
//      primary: $tumi-app-primary,
//      accent: $tumi-app-accent,
//      warn: $tumi-app-warn,
//    ),
//  )
//);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include mat.all-component-themes($tumi-app-theme);

$theme: mat.define-theme(
  (
    color: (
      //theme-type: dark,
      primary: mat.$azure-palette,
    ),
    typography: (
      brand-family: 'General Sans, sans-serif',
      plain-family: 'General Sans, sans-serif',
    ),
  )
);

$dark-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: mat.$azure-palette,
    ),
    typography: (
      brand-family: 'General Sans, sans-serif',
      plain-family: 'General Sans, sans-serif',
    ),
  )
);

html {
  // Apply the base theme at the root, so it will be inherited by the whole app.
  @include mat.all-component-themes($theme);
}

/* You can add global styles to this file, and also import other style files */

// Fix for outlined material fields with tailwind
.mdc-notched-outline__notch {
  border-right: none;
}

.mat-mdc-button-base {
  line-height: normal;
}

.nav-bottom-sheet {
  mat-bottom-sheet-container.mat-bottom-sheet-container {
    @apply bg-blue-600;
  }
}

.error-button {
  @include mat.button-color($theme, $color-variant: error);
}

@layer base {
  html {
    box-sizing: border-box;
    font-weight: 400;
  }

  //html,
  //body {
  //  height: 100%;
  //}
  body {
    margin: 0;
    @apply font-sans;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    -webkit-tap-highlight-color: transparent;
  }

  h1 {
    @apply font-display text-2xl;
  }

  h2 {
    @apply font-display text-xl;
  }

  h3 {
    @apply text-lg;
  }

  mat-icon.mat-icon.card-icon {
    @apply h-8 w-8 rounded-lg p-1 lg:h-10 lg:w-10;
  }

  markdown {
    img {
      @apply lg:float-right lg:ml-4 lg:max-w-lg xl:max-w-2xl;
    }
  }

  .dark-button {
    @include mat.button-color($dark-theme);
    @include mat.fab-color($dark-theme);
    @include mat.icon-button-color($dark-theme);
  }

  .photo-view {
    mat-dialog-container {
      padding: 0;
    }
  }

  //.cdk-overlay-pane.modern {
  //  .mat-mdc-dialog-container .mdc-dialog__surface {
  //    @apply rounded-3xl bg-primary-container text-primary-onContainer;
  //  }
  //}

  //.mat-mdc-button-base.modern {
  //  @apply rounded-full;
  //}

  //mat-panel-title {
  //  @apply font-bold lg:text-lg;
  //}

  //.bg-blur {
  //  backdrop-filter: blur(16px) saturate(180%);
  //}

  .mat-mdc-list-item-avatar {
    border-radius: 0 !important;
  }

  .save-button {
    .mat-icon {
      mat-spinner {
        height: 24px !important;
        width: 24px !important;

        circle {
          @apply stroke-gray-400;
        }

        svg {
          height: 24px !important;
          width: 24px !important;
        }
      }
    }
  }

  //mat-toolbar {
  //  background: white !important;
  //  @apply shadow-sm;
  //}

  /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
  mat-progress-bar {
    position: absolute !important;
    z-index: 1000 !important;
    @apply sm:max-w-[calc(100vw-96px)];
  }

  @media only percy {
    .hide-in-percy {
      visibility: hidden;
    }
  }
}

@layer components {
  .skeleton {
    @apply animate-pulse cursor-default rounded-md bg-slate-200 box-decoration-clone text-transparent;
  }
}
